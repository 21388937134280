import React from "react";

import LayoutWrapper from "../layout-wrapper";
import { scrollTo } from "../../../utils";
import { BANKIRU_URL } from "../../../constants";

import styles from "./styles.module.styl";

import logo from "./static/logo.svg";

const scroll = (id) => {
  return () => {
    const element = document.getElementById(id);
    scrollTo(element.offsetTop, 750);
  };
};

export default () => (
  <LayoutWrapper>
    <header className={styles.header}>
      <a href={BANKIRU_URL}>
        <img className={styles.logo} src={logo} alt="логотип банки.ру" />
      </a>
      <div>
        <span onClick={scroll("about")} className={styles.menuItem}>
          о премии
        </span>
        <span onClick={scroll("nominations")} className={styles.menuItem}>
          номинации
        </span>
      </div>
    </header>
  </LayoutWrapper>
);
