import sberbank from "./images/sberbank.svg";
import alfabank from "./images/alfabank.svg";
import tinkoff from "./images/tinkoff.svg";
import alfaInsurance from "./images/alfa-insurance.svg";
import zaimer from "./images/zaimer.svg";
import zetta from "./images/zetta.svg";
import sovkombank from "./images/sovkombank.svg";
import vtb from "./images/vtb.svg";
import gazprombank from "./images/gazprombank.svg";
import domrf from "./images/domrf.svg";
import lokobank from "./images/lokobank.svg";
import eastbank from "./images/eastbank.svg";
import rosbank from "./images/rosbank.svg";
import moneyman from "./images/moneyman.svg";
import dot from "./images/dot.svg";
import man from "./images/man.jpg";
import bankiru from "./components/header/static/logo.svg";
import bankir from "./images/bankir.jpg";

export const nominations = [
  {
    id: "01",
    nomination: "банк года",
    description: `В 2020 году запуск экосистемы «Сбер» позволил банку показать колоссальный органический рост бизнеса — на 21%, или почти на 6 трлн рублей. При этом сохранены высокие показатели эффективности бизнеса, несмотря на кризисные явления.`,
    logo: sberbank,
    alt: "сбербанк",
    href: "https://www.banki.ru/banks/bank/sberbank/",
  },
  {
    id: "02",
    nomination: "банкир года",
    description: `Под руководством Владимира Верхошинского банк занимает высокие позиции на российском банковском рынке, стабильно развивается и остается прибыльным даже в кризисные времена.`,
    logo: alfabank,
    alt: "альфабанк",
    href: "https://www.banki.ru/banks/bank/alfabank/",
    user: {
      name: "Владимир Верхошинский",
      avatar: bankir,
    },
  },
  {
    id: "03",
    nomination: "МФК года",
    description: `Компания показала значительный рост портфеля микрозаймов среди десяти крупнейших микрофинансовых компаний в ренкингах «Эксперт РА». В течение года демонстрировала  устойчивость положения, динамику развития, инновационные технологии.`,
    logo: zaimer,
    alt: "займер",
    href: "https://www.banki.ru/microloans/companies/mfo/zaymer/",
  },
  {
    id: "04",
    nomination: "Страховая компания года",
    description: `Быстро развивающаяся страховая компания в сфере IT-технологий. Динамично задает тенденцию рынку по продуктовой линейке и новым дополнительным продуктам внутри имеющихся. Лидер среди страховых компаний по продажам е-ОСАГО, демонстрирующий лояльность по отношению к своим партнерам.`,
    logo: alfaInsurance,
    alt: "альфа страхование",
    href: "https://www.banki.ru/insurance/companies/alfastrahovanie/",
  },
  {
    id: "05",
    nomination: "Инвестиционная компания года",
    description: `К концу 2020 года заняла первое место по количеству зарегистрированных на Московской бирже клиентов с результатом в 3,5 млн и подтвердила свое абсолютное лидерство по активным клиентам, увеличив их за год более чем в 7 раз.`,
    logo: tinkoff,
    alt: "тинькофф",
    href: "https://www.banki.ru/banks/bank/tcs/",
  },
  {
    id: "06",
    nomination: "Народный рейтинг банков",
    description: `Победитель «Народного рейтинга» банков по итогам 2020 года.`,
    logo: sovkombank,
    alt: "совкомбанк",
    href: "https://www.banki.ru/banks/bank/sovcombank/",
  },
  {
    id: "07",
    nomination: "Народный рейтинг страховых компаний",
    description: `Победитель «Народного рейтинга» страховых компаний по итогам 2020 года.`,
    logo: zetta,
    alt: "зетта страхование",
    href: "https://www.banki.ru/insurance/companies/zettastrahovanie/",
  },
  {
    id: "08",
    nomination: "Пресс-служба года",
    description: `За быструю реакцию на информационную повестку, качественную аналитику, честные ответы и информативные пресс-мероприятия.`,
    logo: vtb,
    alt: "втб",
    href: "https://www.banki.ru/banks/bank/vtb/",
  },
  {
    id: "09",
    nomination: "Забота о клиенте",
    description: `Самый клиентоориентированный банк по итогам работы на Банки.ру в 2020 году, готовый оперативно прийти на помощь клиенту в режиме 24/7.`,
    logo: vtb,
    alt: "втб",
    href: "https://www.banki.ru/banks/bank/vtb/",
  },
  {
    id: "10",
    nomination: "Вклад года",
    description: `Сочетает в себе выгодную ставку и гибкие условия. Возможность выбора срока размещения, получение процентов на карту или их капитализация и дополнительные взносы. Максимальная ставка по вкладу достигает 5,25% годовых!`,
    logo: tinkoff,
    alt: "тинькофф",
    href:
      "https://www.banki.ru/products/deposits/deposit/14907/?rate_id=2523622&amount=0",
  },
  {
    id: "11",
    nomination: "Потребительский кредит года",
    description: `Крупная сумма без обеспечения, низкие, дифференцированные в зависимости от суммы ставки, лояльные требования к заемщикам и удобство оформления. Кредит доступен пенсионерам, а решение о выдаче принимается в день обращения. Эти параметры вывели продукт в победители.`,
    logo: gazprombank,
    alt: "газпромбанк",
    href: "https://www.banki.ru/products/credits/credit/4578/",
  },
  {
    id: "12",
    nomination: "Накопительный счет года",
    description: `Клиенты могут получать максимальный доход и при этом свободно распоряжаться своими средствами: пополнение и снятие, ежемесячная выплата процентов или их капитализация, досрочное расторжение без потери дохода. Ставка в течение года достигала 6% годовых — без соблюдения дополнительных условий.`,
    logo: alfabank,
    alt: "альфабанк",
    href:
      "https://www.banki.ru/products/deposits/deposit/18953/?period=1824&rate_id=2689579&amount=0",
  },
  {
    id: "13",
    nomination: "Ипотечный кредит года",
    description: `Этот продукт позволяет максимально снизить долговую нагрузку большинству заемщиков — вне зависимости от их занятости. Продукт сочетает в себе низкие ставки, размер К/З до 90%, особые условия для медработников на фоне пандемии и удобный формат проведения сделки.`,
    logo: domrf,
    alt: "дом.рф",
    href: "https://www.banki.ru/products/hypothec/credit/10246/",
  },
  {
    id: "14",
    nomination: "Банковская карта года",
    description: `Карта позволяет совершать покупки с максимальной выгодой благодаря ежемесячному выбору категорий с повышенным кешбэком. Предусматривает процент на остаток, бесплатное снятие наличных в любых банкоматах и выгодные условия обслуживания.`,
    logo: tinkoff,
    alt: "тинькофф",
    href: "https://www.banki.ru/products/debitcards/card/7426/",
  },
  {
    id: "15",
    nomination: "Спецпредложение года",
    description: `Победитель стал лидером по активному привлечению клиентов. В 2020 году банк улучшил условия для наших пользователей по всем накопительным продуктам — вкладам, накопительным счетам и дебетовым картам.`,
    logo: lokobank,
    alt: "локо банк",
    href: "https://www.banki.ru/specials/deposits/poluchay_procenti_trigoda/",
  },
  {
    id: "16",
    nomination: "Онлайн-заявка на кредит",
    description: `Заявку на кредит в этот банк отправил каждый пятый пользователь, подбиравший себе кредит на Банки.ру в 2020 году. Предложение пользовалось популярностью благодаря комфортным условиям, доступности во многих регионах, технологичному и быстрому процессу оформления заявки.`,
    logo: eastbank,
    alt: "банк восточный",
    href: "https://www.banki.ru/products/credits/v-express-bank/",
  },
  {
    id: "17",
    nomination: "Онлайн-заявка на ипотеку",
    description: `В 2020 году более 90 тыс. пользователей Банки.ру отправили заявки на ипотеку в «Росбанк Дом».Банк активно работал над оптимизацией клиентского пути, упрощал процесс подачи заявки и создавал новые удобные дистанционные сервисы для клиентов. Благодаря этому компания получила более 20% заявок клиентов через онлайн-каналы.`,
    logo: rosbank,
    alt: "росбанк",
    href: "https://www.banki.ru/products/hypothec/rosbank/",
  },
  {
    id: "18",
    nomination: "Онлайн-заявка на микрозаем",
    description: `Удобство сервиса, надежность и популярность бренда.`,
    logo: moneyman,
    alt: "money man",
    href:
      "https://www.banki.ru/microloans/companies/mfo/moneyman/?source=mfo_companies",
  },
  {
    id: "19",
    nomination: "Онлайн-заявка на РКО",
    description: `Самые востребованные тарифы банка «Точка» в разделе по подбору расчетного счета для бизнеса на Банки.ру.`,
    logo: dot,
    alt: "банк точка",
    href: "https://www.banki.ru/products/rko/tochka/",
  },
  {
    id: "20",
    nomination: "Человек года",
    description: `Победитель выбран на основании открытого голосования пользователей форума.`,
    logo: bankiru,
    alt: "банки.ру",
    href: "https://www.banki.ru/profile/?UID=46595",
    user: {
      name: "BAY",
      avatar: man,
    },
  },
];
