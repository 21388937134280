import { getBankOfYearUrl } from "../constants";

const easeInOutQuad = (t, b, c, d) => {
  t /= d / 2;
  if (t < 1) {
    return (c / 2) * t * t + b;
  }
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};

export const scrollTo = (to, duration) => {
  const start = window.scrollY;
  const change = to - start;
  const increment = 20;
  let currentTime = 0;

  const animateScroll = () => {
    currentTime += increment;
    const val = easeInOutQuad(currentTime, start, change, duration);
    window.scrollTo(0, val);
    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  };
  animateScroll();
};

export const initSocialButtons = () => {
  let script = document.createElement("script");
  script.onload = () => {
    window.RamblerShare.init(".rambler-share", {
      style: {
        iconSize: 24,
        borderRadius: 27,
        borderWidth: 0,
        counterSize: 16,
        buttonBackground: "transparent",
        iconColor: "#0E173E",
        textColor: "#1b232b",
        iconColorHover: "#1b232b",
        textColorHover: "#1b232b",
      },
      url: getBankOfYearUrl(2020),
      counters: true,
      buttons: ["vkontakte", "facebook", "odnoklassniki", "twitter"],
      title: "Церемония «Банк года '20»",
      description: `Главная финансовая премия от крупнейшего независимого финансового портала Банки.ру. 18 февраля 2021`,
      image:
        "https://static4.banki.ru/ugc/specials20/dd74/1613493048/og-image.png",
    });
  };
  script.async = true;
  script.src = "https://developers.rambler.ru/likes/v1/widget.js";
  document.head.appendChild(script);
};
