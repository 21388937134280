import React from "react";
import { Helmet } from "react-helmet";

import Header from "./components/header";
import BankOfYear from "./components/bank-of-year";
import About from "./components/about";
import Nominations from "./components/nominations";
import Ceremonies from "./components/ceremonies";
import Footer from "./components/footer";
import { nominations } from "./data";
import { getBankOfYearUrl } from "../constants";

import "./styles.styl";

export default function Home() {
  return (
    <div>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Manrope:wght@300;400&display=swap"
          rel="stylesheet"
        />
        <title>Церемония «Банк года '20» | Банки.ру»</title>
        <meta
          name="description"
          content="Главная финансовая премия BANKI.RU. 18 февраля 2021, Москва. Крупнейший независимый финансовый портал."
        />
        <meta
          name="keywords"
          content="Банк года 2020,церемония Банк года,рейтинг банков,список банков,рейтинг банков москвы,сбербанк,банки москвы,центральный банк,рейтинг банков,банки"
        />
        <meta property="og:title" content="Церемония «Банк года '20»" />
        <meta property="twitter:title" content="Церемония «Банк года '20»" />
        <meta
          property="og:description"
          content="Главная финансовая премия от крупнейшего независимого финансового портала Банки.ру. 18 февраля 2021"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://static4.banki.ru/ugc/specials20/dd74/1613493048/og-image.png"
        />
        <meta property="og:url" content={getBankOfYearUrl(2020)} />
      </Helmet>
      <Header />
      <BankOfYear />
      <About />
      <Nominations nominations={nominations} />
      <Ceremonies />
      <Footer />
    </div>
  );
}
