import React from "react";

import styles from "./styles.module.styl";

export default ({ nomination }) => (
  <a href={nomination.href} target="_blank" className={styles.root}>
    <div className={styles.id}>{nomination.id}</div>
    <div className={styles.info}>
      <div className={styles.text}>
        <div className={styles.nomination}>{nomination.nomination}</div>
        <img
          className={styles.logoMobile}
          src={nomination.logo}
          alt={`логотип ${nomination.alt}`}
        />
        <div className={styles.description}>{nomination.description}</div>
        {nomination.user && (
          <div className={styles.user}>
            <img className={styles.avatar} src={nomination.user.avatar} />
            <div className={styles.name}>{nomination.user.name}</div>
          </div>
        )}
      </div>
      <div className={styles.logo}>
        <img
          className={styles.image}
          src={nomination.logo}
          alt={`логотип ${nomination.alt}`}
        />
      </div>
    </div>
  </a>
);
