import React, { useEffect, useState } from "react";

import LayoutWrapper from "../layout-wrapper";

import styles from "./styles.module.styl";

import background from "./static/background.png";
import bankOfYear from "./static/bank-of-year.png";
import corner from "./static/corner.svg";
import video from "./static/video.mp4";

export default () => {
  const [isMobile, setIsMobile] = useState(true);
  useEffect(() => {
    setIsMobile(!!window.navigator.maxTouchPoints);
  }, []);

  return (
    <section>
      <div className={styles.background}>
        {isMobile ? (
          <img src={background} className={styles.image} alt="фон" />
        ) : (
          <video
            className={styles.video}
            loop
            poster={background}
            autoPlay
            muted
          >
            <source src={video} type="video/mp4" />
          </video>
        )}
      </div>
      <LayoutWrapper>
        <h1 className={styles.header}>XIV Ежегодная церемония</h1>
        <img
          src={bankOfYear}
          className={styles.bankOfYear}
          alt="банк года 2020"
        />
        <div className={styles.date}>18 февраля 2021</div>
        <img src={corner} className={styles.corner} alt="граница" />
      </LayoutWrapper>
    </section>
  );
};
