import React from "react";

import LayoutWrapper from "../layout-wrapper";

import styles from "./styles.module.styl";

import "./static/about-background.png";

export default () => (
  <section id="about">
    <div className={styles.background} />
    <LayoutWrapper>
      <div className={styles.header}>
        <div>18 февраля 2021 года</div>
        <div>состоялась 14-я главная</div>
        <div>финансовая премия «Банк года»</div>
      </div>
      <div className={styles.aside}>
        <div className={styles.info}>
          <div className={styles.textBlock}>
            Впервые награждение состоялось в онлайн-формате.
            <br />
            Заслуженные награды и дипломы получили победители в 20 номинациях.
          </div>
          <div className={styles.textBlock}>
            Банки.ру награждает лучшие компании на финансовом рынке России по
            итогам календарного года, чтобы предоставить клиентам достоверную и
            непредвзятую информацию для выбора надежного партнера среди
            компаний, бизнесу — ориентиры для дальнейшего развития.
          </div>
          <div>
            «Банк года» © — ежегодная профессиональная премия, учрежденная
            Банки.ру в 2007 году.
          </div>
        </div>
      </div>
    </LayoutWrapper>
  </section>
);
