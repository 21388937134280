import React, { useEffect } from "react";

import LayoutWrapper from "../layout-wrapper";
import { getBankOfYearUrl, MAX_YEAR } from "../../../constants";
import { initSocialButtons } from "../../../utils";

import styles from "./styles.module.styl";

const years = [];

for (let i = MAX_YEAR; i >= 2007; i--) {
  years.push(i);
}

const CURRENT_YEAR = 2020;

export default () => {
  useEffect(() => {
    initSocialButtons();
  }, []);

  return (
    <LayoutWrapper>
      <div className={styles.header}>Все церемонии</div>
      <div className={styles.ceremoniesContainer}>
        <div
          className={styles.gradient}
          style={{ width: `${(years.length - 2) * 96 + 120}px` }}
        >
          {years.map((year) => (
            <div key={year} className={`${styles.element} ${year === CURRENT_YEAR ? styles.currentYear : ''}`}>
              <div className={styles.dotContainer}>
                <div className={styles.dot}>
                  <a href={getBankOfYearUrl(year)} className={styles.border}>
                    <div className={styles.fill} />
                    <div className={styles.year}>{year}</div>
                  </a>
                </div>
              </div>
            </div>
          ))}
          <div className={styles.line} />
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.hashtags}>
          <span className={styles.hashtag}>#банкгода</span>
          <span className={styles.hashtag}>#банкиру</span>
        </div>
        <div className="rambler-share"></div>
      </div>
    </LayoutWrapper>
  );
};
