import React from "react";

import LayoutWrapper from "../layout-wrapper";

import styles from "./styles.module.styl";

export default () => (
  <footer className={styles.footer}>
    <LayoutWrapper className="layout-wrapper">
      <div className={styles.text}>
        <div>© 2005—2021</div>
        <div>&nbsp;ИА «Банки.ру»</div>
      </div>
    </LayoutWrapper>
  </footer>
);
