import React, { useState, useEffect } from "react";

import LayoutWrapper from "../layout-wrapper";
import Nomination from "../nomination";

import styles from "./styles.module.styl";

import arrow from "./static/arrow.svg";
import background from "./static/background.png";

const DEFAULT_SHOW = 5;

export default ({ nominations }) => {
  const [open, setOpen] = useState(false);
  const [nominationsForShow, setNominationsForShow] = useState([]);

  const onClick = () => {
    setOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const forShow = open ? nominations : nominations.slice(0, DEFAULT_SHOW);
    setNominationsForShow(forShow);
  }, [open, nominations]);

  return (
    <section id="nominations">
      <div className={styles.root}>
        <img src={background} className={styles.background} alt="фон" />
        <LayoutWrapper>
          <div className={styles.header}>
            <div className={styles.space} />
            <div className={styles.text}>номинации</div>
          </div>
          <div className={styles.nominations}>
            {nominationsForShow &&
              nominationsForShow.map((nomination) => (
                <Nomination key={nomination.id} nomination={nomination} />
              ))}
          </div>
        </LayoutWrapper>
      </div>
      <div className={styles.footerRoot}>
        <LayoutWrapper>
          <div className={styles.footer} onClick={onClick}>
            <div className={styles.space}>
              <img
                src={arrow}
                className={open ? styles.arrow : ""}
                alt="переключатель"
              />
            </div>
            <div className={styles.text}>
              {open ? "свернуть" : "все победители"}
            </div>
          </div>
        </LayoutWrapper>
      </div>
    </section>
  );
};
